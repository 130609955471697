<template>
  <u-tooltip v-if="showBadge" :text="config.tooltip" :popper="{ placement: 'top' }" :prevent="hideTooltip">
    <u-button
      size="2xs"
      class="shadow flex-shrink"
      :icon="config.icon"
      :color="config.color"
      :label="`${config.label} ${album.permission_groups_count ? `(${album.permission_groups_count})` : ''}`"
      @click.stop.prevent="share"
      truncate
      :ui="btnUiConfig"
    />
  </u-tooltip>
</template>

<script setup>
  const props = defineProps({
    album: Object,
    hideTooltip: Boolean,
    size: String
  });

  const isOwner = computed(() => props.album.user_id === useSessionStore().currentUserId);
  const showBadge = computed(() => isOwner.value || props.album.sharedItemId);

  const config = computed(() => {
    if (isOwner.value) {
      return ALBUM_PERMISSION_LAYERS[props.album.privacy];
    } else if (props.album.sharedItemId) {
      const userName = props.album.user.name;
      return {
        icon: 'i-ri-user-fill',
        color: 'charcoal',
        label: userName,
        tooltip: `Owned by ${userName}`
      };
    }
  });

  const btnUiConfig = computed(() => {
    if (props.size === 'sm') {

      return {
        base: 'flex-1',
        size: {
          '2xs': 'text-2xs'
        },
        padding: {
          '2xs': 'px-1 py-1'
        },
        icon: {
          size: {
            '2xs': 'h-2.5 w-2.5'
          }
        }
      }
    }

    return {base: 'flex-1'};
  })


  function share() {
    if (isOwner.value) {
      useAlbumActions().share({album: props.album});
    }
  }
</script>
